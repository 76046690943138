import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, email, maxLength, minLength, sameAs} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'password-restore',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        password: '',
        password_confirmation: '',
        token: this.$route.query.token,
        email: this.$route.query.email
      },
      validationErrors: {},
      isShowMessage: false,
    }
  },
  components: {},
  validations: {
    payload: {
      password: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(20),
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      },
    }
  },
  computed: {
    ...mapGetters({
      workersList: 'administration/workersList',
      isPasswordRestoreLoading: 'auth/isPasswordRestoreLoading',
    }),
    isDisabled() {
      if (
        this.payload.password === '' ||
        this.payload.password_confirmation === ''
      ) {
        return true
      } else {
        return false
      }
    },
    passwordErrors() {
      let error = [];
      if (!this.$v.payload.password.$dirty) {
        return error;
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password.minLength) {
        error.push(this.$t('validationMin', {count: 4}));
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('validationMax', {count: 20}));
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    passwordConfirmationErrors() {
      let error = [];
      if (!this.$v.payload.password_confirmation.$dirty) {
        return error;
      }
      if (!this.$v.payload.password_confirmation.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password_confirmation.sameAsPassword) {
        error.push(this.$t('validationSameAs'));
      }
      if (this.validationErrors.password_confirmation) {
        this.validationErrors.password_confirmation.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },

  },
  methods: {
    ...mapActions({
      restorePassword: 'auth/AUTH_REQUEST_PASSWORD_RESTORE',
    }),
    resetForm() {
      this.$v.$reset();
      this.payload.password = '';
      this.payload.password_confirmation = '';
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.restorePassword(this.payload)
          .then(() => {
            this.isShowMessage = true;
            this.resetForm();
          }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            this.validationErrors = error.response.data.errors
          }
        });
      }
    }
  }
}
